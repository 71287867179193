import { Accordion, Button, Card, Grid, IconButton, InputBase, List } from "@mui/material";
import { styled } from '@mui/system';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import CancelIcon from '@mui/icons-material/Cancel';

export const AccordionStyled = styled(Accordion)({
  width: "100%",
  overflow: "auto",
  borderRadius: "10px",
  marginBottom: "10px"
});

export const SelectListStyled = styled(List)({
  width: "100%",
  overflow: "auto"
});

export const InputBaseStyled = styled(InputBase)({
  width: "100%"
});

export const NewAddressContainer = styled(Grid)({
  paddingLeft: "20px",
  paddingRight: "20px",
  marginBottom: "10px"
});

export const ApartmentNoCard = styled(Card)({
  width: "100%",
  borderRadius: "10px"
});

export const ApartmentNoInput = styled(InputBase)({
  padding: "10px 10px 10px 20px"
});

export const AddressCard = styled(Card)({
  borderRadius: "10px",
  height: "70px",
  width: "100%"
});

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.paulini.error,
  position: "absolute",
  right: "0",
  top: "-15px"
}));


export const SadIcon = styled(SentimentDissatisfiedIcon)({
  color: "gray",
  height: "100px",
  width: "100px"
});

export const CancelIconStyled = styled(CancelIcon)({
  height: "33px",
  width: "33px"
});