import { Grid, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useTranslation } from "react-i18next";
import { AppStyle } from "../../helpers/appStyle";
import { appStyleMode } from "../../helpers/clientConfigs";

export function NoChoosePoint() {
    const { t } = useTranslation('salesPoint');
    const getColor = () => {
        return appStyleMode === AppStyle.Mobile ? "gray" : "white";
    };

    return (
        <Grid sx={{ height: "300px", mt: "10px" }} container alignItems="center" direction="column">
            <Typography sx={{ fontSize: "20px", color: getColor() }}>{t('noSalesPoint')}</Typography>
            <SentimentDissatisfiedIcon sx={{ color: getColor(), height: "100px", width: "100px" }} />
        </Grid>
    );
}