export const GET_PARAMS = {
  popup: "popup",
  notificationId: "notification-id",
  pageId: "page_id",
};

export const GET_ENUMS = {
  popup: {
    confirmPhone: "confirm-phone",
    confirmEmail: "confirm-email"
  },
};