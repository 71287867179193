import { Button, Card, Grid, Typography, CardHeader, CardContent, TextField, Box } from "@mui/material";
import { NavBar } from "../appBars/navBar/navBar";
import { useTranslation } from 'react-i18next';

export function Support() {
    const { t } = useTranslation('info');

    return (
        <Grid>
            <NavBar barTitle={t('contactUs')} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>

                <Card sx={{ mt: "10px" }}>
                    <CardHeader sx={{ backgroundColor: "paulini.blue" }}
                        title={<Typography sx={{ fontWeight: "normal", fontSize: "17px", color: "#fcfcfc" }}>
                            {t('helpUs')}
                        </Typography>} />
                    <CardContent>
                        <Grid container justifyContent="center">
                            <TextField placeholder={t('write')} InputProps={{ disableUnderline: true }} variant="standard"
                                sx={{ borderColor: "#e8e8e8", borderStyle: "solid", borderWidth: "2px", borderRadius: "10px", width: "100%", padding: "5px" }} multiline rows={10} />
                        </Grid>
                        <Button sx={{ color: "white", backgroundColor: "paulini.orange", mt: "10px", float: "right", mb: "15px" }} variant="contained">{t('inform')}</Button>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    );
}