import { AppBar, Toolbar, Grid, Typography, Box, IconButton, useMediaQuery, styled } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import { RootState } from "../../../redux/store";
import PauliniTextLogo from "../../../logo/textLogo.png"
import PauliniLogo from "../../../logo/logo.png"
import { HomeItemsGrid, PauliniLogoImg, PauliniTextLogoImg } from "../appBarSyles"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { HomeBarItem } from "./homeBarItem"
import InfoIcon from "../../../logo/info.png"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";
import MenuIcon from '@mui/icons-material/Menu';
import { LanguageMenu } from "./languageMenu";
import { CustomerInfo } from "orderme-api-integration-client";
import { roundDoubleNumbers } from "../../../helpers/currency";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CartBarBadgeButton } from "../../shoppingCart/cartBarBadgeButton";
import { closeMenu, openMenu } from "../../../redux/reducers/menuReducer";
import { useTranslation } from "react-i18next";
import { HomeBarLink } from "./homeBarLink";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.paulini.blue,
  width: "auto",
  minWidth: "300px",
  paddingRight: "48px",
  '@media (max-width: 600px)': {
    paddingRight: "24px"
  },
}));

export function HomeBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
  const isPasswordRestored: boolean = useAppSelector((state: RootState) => state.authState.isPasswordRestored);
  const isMenuOpened: boolean = useAppSelector((state: RootState) => state.menuState.isMenuOpened);
  const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
  const isSmallSize = useMediaQuery('(max-width:700px)');
  const isLargeSize = useMediaQuery('(min-width:1400px)');
  const { t } = useTranslation();

  const handleMenuClick = () => {
    if (isMenuOpened)
      dispatch(closeMenu());
    else
      dispatch(openMenu());
  };

  const goToSearchSection = () => {
    navigate("/search");
  }

  const goToHome = () => {
    navigate("/");
  }

  const mobileToolbar = () => {
    return (
      <AppBar sx={{ backgroundColor: "paulini.blue", height: "50px" }} position="static">
        <Toolbar>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <PauliniTextLogoImg width={100} src={PauliniTextLogo} alt="PAULINI drabužių valykla" />
            <HomeItemsGrid>
              <HomeBarItem icon={<LocationOnIcon />} open={() => navigate('/point/All')} />
              <HomeBarItem icon={<img height={25} width={25} src={InfoIcon} alt="Info" />} open={() => navigate("/info")} />
              <HomeBarItem icon={<SearchIcon />} open={() => goToSearchSection()} />
              <HomeBarItem icon={<AccountCircleIcon />} open={() => navigate("/account", { state: true })} />
              <HomeBarItem icon={<HistoryIcon />} open={() => navigate("/orderHistory")} isVisible={isUserLoggedIn && !isPasswordRestored} />
            </HomeItemsGrid>
          </Grid>
        </Toolbar>
      </AppBar>)
  }

  const websiteToolbar = () => {
    return (
      <Grid sx={{ width: '100%', zIndex: 1200, maxWidth: "xl" }} position="fixed">
        <StyledAppBar elevation={0}
          sx={{
            height: isLargeSize ? "140px" : "100px",
            py: "10px"
          }}
          position="static">
          {isLargeSize && <PauliniLogoImg width={200} sx={{ mx: "auto", mb: "10px" }} src={PauliniLogo} alt="PAULINI drabužių valykla" onClick={() => goToHome()} />}
          <Grid container direction="row">
            <Grid item xs container>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleMenuClick} sx={{ p: "6px", ml: "-6px", height: "auto" }}>
                  <MenuIcon
                    sx={{
                      color: 'white',
                      width: "50px",
                      height: "50px",
                      '@media (max-width: 400px)': {
                        width: "40px",
                        height: "40px"
                      }
                    }}
                  />
                </IconButton>
                <LanguageMenu></LanguageMenu>
              </Box>
              {isUserLoggedIn && !isSmallSize && userBarItem()}
            </Grid>
            {isLargeSize ?
              <Grid item xs="auto" sx={{ width: "600px", mr: "50px" }}>
                <Grid sx={{ height: "60px" }} container direction="row" alignItems="center">
                  <HomeBarLink link="/point/All" text={t('salesPoint:salesPoints')} />
                  <HomeBarLink link="/news" text={t('news:menuTitle')} />
                  <HomeBarLink link="/aboutUs" text={t('info:aboutUs')} />
                </Grid>
              </Grid> :
              <Box>
                <PauliniLogoImg width={200} sx={{ ml: "0px" }} src={PauliniLogo} alt="PAULINI drabužių valykla" onClick={() => goToHome()} />
              </Box>
            }
            <Grid item xs container alignItems="center" justifyContent="flex-end" sx={{ width: "210px", mr: "-8px" }}>
              {!isSmallSize && (<CartBarBadgeButton />)}
              <HomeBarItem icon={<SearchIcon />} open={() => goToSearchSection()} />
            </Grid>
          </Grid>
        </StyledAppBar>
        {isSmallSize && userAndCartBadgeBar()}
      </Grid >)
  }

  const userAndCartBadgeBar = () => {
    return (
      <StyledAppBar elevation={0}
        sx={{ height: "50px" }}
        position="static">
        <Grid container direction="row">
          {isUserLoggedIn && userBarItem()}
          <Grid item xs container alignItems="center" justifyContent="flex-end">
            <CartBarBadgeButton />
          </Grid>
        </Grid>
      </StyledAppBar>)
  }

  const userBarItem = () => {
    return (
      <Grid item xs sx={{ pl: "4px" }} container direction="row" alignItems="center">
        <Typography sx={{ mr: "5px" }}>
          {customerInfo.name}
        </Typography>
        <Grid sx={{ width: "auto" }} container direction="row" alignItems="center">
          <Typography>
            {roundDoubleNumbers(customerInfo.credit)} €
          </Typography>
          {!isPasswordRestored &&
            <IconButton onClick={() => navigate('/credits')} sx={{ p: "5px", color: 'white' }}>
              <AddCircleOutlineIcon sx={{ height: "35px", width: "35px" }} />
            </IconButton>
          }
        </Grid>
      </Grid>)
  }

  return (
    <Grid>
      {appStyleMode === AppStyle.Paulini ? websiteToolbar() : mobileToolbar()}
    </Grid>
  );
}