import { LoginDialog } from "../auth/login/loginDialog"
import { HashRouter } from "react-router-dom"
import { RootState } from '../../redux/store';
import GetParameterPopups from '../../popups';
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { resetLoginRequired } from "../../redux/reducers/authReducer"
import { LoadFailed } from "../loadFailed/loadFailed"
import MobileRoutes from "../routes/mobileRoutes"

function MobileContent() {
    var dispatch = useAppDispatch();
    const isDisconnected: boolean = useAppSelector((state: RootState) => state.networkConnectionState.isDisconnected);
    const isLoginRequired: boolean = useAppSelector((state: RootState) => state.authState.isLoginRequired);

    const closeLogin = () => {
        dispatch(resetLoginRequired());
    }

    return (isDisconnected ?
        <LoadFailed /> :
        <HashRouter>
            <MobileRoutes />
            <GetParameterPopups />
            <LoginDialog open={isLoginRequired} onClose={closeLogin} />
        </HashRouter >
    )
}

export default MobileContent;
