import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NearestSalesPoint } from "orderme-api-integration-client";
import { NavBar } from "../appBars/navBar/navBar"
import { useAppDispatch } from "../../redux/hooks";
import { PointInfo } from "./pointInfo";

export function SelectedPoint() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const salesPoint = location.state as NearestSalesPoint;

    useEffect(() => {
        if (!salesPoint) {
            navigate(-1);
        }
    }, [])

    return (
        <>
            <NavBar barTitle={salesPoint?.value.name} />
            {salesPoint && (<PointInfo salesPoint={salesPoint}></PointInfo>)}
        </>
    );
}