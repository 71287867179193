import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import i18next from "i18next";
import { getLanguage, setLanguage } from "../../../helpers/localStorageService";
import { useEffect, useState } from "react";
import { availableLanguages, defaultLanguage } from "../../../i18n/language";
import { LanguageItem } from "../../../interfaces/interfaces";

export function LanguageMenu() {
    const [currentLanguage, setCurrentLanguage] = useState<LanguageItem>(defaultLanguage);

    useEffect(() => {
        const languageKey = getLanguage();
        const language = availableLanguages.find((lang) => lang.key == languageKey);
        if (language)
            setCurrentLanguage(language);
    }, []);

    const changeLanguage = (lang: LanguageItem) => {
        if (lang.key === currentLanguage.key) {
            setAnchorEl(null);
            return;
        }

        i18next.changeLanguage(lang.key)
            .then(() => {
                setLanguage(lang);
                window.location.reload();
            }).catch((error) => {
                console.log(error);
            });
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-controls={open ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Avatar sx={{ height: "30px", width: "30px" }} alt={currentLanguage.key} src={currentLanguage.image} />
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiMenu-paper":
                    {
                        backgroundColor: "#71c1e4"
                    },
                }}>
                {availableLanguages.map((option, index) => (
                    <MenuItem sx={{ px: "12px" }} key={index} onClick={() => changeLanguage(option)}>
                        <Avatar sx={{ height: "30px", width: "30px" }} alt={option.key} src={option.image} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}