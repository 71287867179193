import { AppBar, Box, Divider, Grid, Link, Typography } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyrightDivider, CopyrightTypography } from "./copyrightBarStyles";

export function CopyrightBar() {
    const { t } = useTranslation('copyright');
    const [currentYear] = useState(() => new Date().getFullYear());

    return (
        <AppBar elevation={0}
            sx={{
                backgroundColor: "paulini.blue",
                textAlign: "center",
                my: "10px"
            }}
            position="static">
            <CopyrightDivider />
            <Grid container direction="row" justifyContent="center" alignItems="baseline">
                <CopyrightIcon sx={{ fontSize: "15px", mr: "5px" }} />
                <CopyrightTypography> 2013-{currentYear} UAB PAULINI</CopyrightTypography>
            </Grid>
            <CopyrightTypography>{t('info')}</CopyrightTypography>
            < Grid container direction="row" justifyContent="center" alignItems="baseline" >
                <CopyrightTypography>{t('contactUs')}</CopyrightTypography>
                <Box>
                    <Link sx={{ color: "white", ml: "5px" }} href="mailto:info@paulini.lt">info@paulini.lt</Link>
                </Box>
            </Grid >
        </AppBar >
    );
}