import axios from "axios";
import {
  AchievementClient,
  CountriesClient,
  CustomersClient,
  DeliveryOptionsClient,
  FilesClient,
  InfoFeedsClient,
  LocatorClient,
  OrdersClient,
  PartnersClient,
  PaymentMethodsClient,
  SalesPointsClient,
  ServicesClient,
  SettingsClient
} from "orderme-api-integration-client";
import { apiKey, basePath } from "./clientConfigs";

export const instance = axios.create({
  baseURL: basePath,
  headers: {
    common: {
      'orderme-api-key': apiKey
    },
  }
});

export class SalePointClient extends SalesPointsClient {
  constructor() {
    super(null, instance);
  }
}

export class LocateClient extends LocatorClient {
  constructor() {
    super(null, instance);
  }
}

export class ServiceClient extends ServicesClient {
  constructor() {
    super(null, instance);
  }
}

export class AchieveClient extends AchievementClient {
  constructor() {
    super(null, instance);
  }
}

export class CountryClient extends CountriesClient {
  constructor() {
    super(null, instance);
  }
}

export class CustomerClient extends CustomersClient {
  constructor() {
    super(null, instance);
  }
}

export class DeliveryClient extends DeliveryOptionsClient {
  constructor() {
    super(null, instance);
  }
}

export class FileClient extends FilesClient {
  constructor() {
    super(null, instance);
  }
}

export class OrderClient extends OrdersClient {
  constructor() {
    super(null, instance);
  }
}

export class PaymentClient extends PaymentMethodsClient {
  constructor() {
    super(null, instance);
  }
}

export class PartnerClient extends PartnersClient {
  constructor() {
    super(null, instance);
  }
}

export class SettingClient extends SettingsClient {
  constructor() {
    super(null, instance);
  }
}

export class InfoFeedClient extends InfoFeedsClient {
  constructor() {
    super(null, instance);
  }
}