import { useState, useEffect } from "react";
import { ListItem, Typography } from "@mui/material";
import { ResetPasswordRequest } from "orderme-api-integration-client";
import { CustomerClient } from "../../../helpers/client"
import { ErrorTypography, InputsListStyled } from "./resetPasswordStyles"
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import { InputField } from "../../inputField/inputField"
import { Popup } from "../../popup/popup"
import { getStatusError } from "../../../helpers/errorMessage";
import { DialogProps } from "../../../interfaces/interfaces";
import { useTranslation } from 'react-i18next';

const inputValues = {
  email: '',
  phone: '',
} as ResetPasswordRequest;

export function ResetPassword(props: DialogProps) {
  const customersClient: CustomerClient = new CustomerClient();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [textFieldValues, setTextFieldValues] = useState<ResetPasswordRequest>(inputValues);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const { onClose, open } = props;

  const { t } = useTranslation('account');
  const { t: tError } = useTranslation('errors');

  const resetPassword = () => {
    setSuccess(false);
    setFailure(false);

    const email = textFieldValues.email !== "" ? textFieldValues.email : null;
    const phone = textFieldValues.phone !== "" ? textFieldValues.phone : null;
    customersClient.resetPassword(new ResetPasswordRequest({ email, phone })).then((response) => {
      setSuccess(true);
      setIsButtonDisabled(true);
      setTimeout(function () {
        handleClose();
      }, 3000);
    })
      .catch((err) => {
        setFailure(true);
        console.log(err);
      });
  }

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    const fieldValue = { [name]: value } as Pick<ResetPasswordRequest, keyof ResetPasswordRequest>;
    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    setTextFieldValues(inputValues);
    onClose();
  };

  useEffect(() => {
    validate();
  }, [textFieldValues]);

  const validate = () => {
    if (Object.values(textFieldValues).some(value => value !== "")) {
      setIsButtonDisabled(false);
    }
    else {
      setIsButtonDisabled(true);
    }
  }

  return (
    <>
      <Popup open={open} onClose={() => handleClose()} dialogTitle={t('resetPasswordTitle')} dialogContent={
        <InputsListStyled>
          <Typography align="center" sx={{ pl: "10px", pt: "10px", fontSize: "18px" }}>{t('enterPhoneOrEmail')}</Typography>
          <InputField
            fieldName="phone"
            inputValue={textFieldValues.phone}
            label={t('phoneNumber')}
            placeholder="+370xxxxxxxx"
            handleFieldChange={handleFieldChange}
            icon={<PhoneAndroidIcon />} />
          <InputField
            fieldName="email"
            inputValue={textFieldValues.email}
            label={t('email')}
            handleFieldChange={handleFieldChange}
            icon={<EmailIcon />} />

          <ListItem>
            {failure && <ErrorTypography>{tError('userNotFound')}</ErrorTypography>}
          </ListItem>
        </InputsListStyled>
      }
        onAction={() => resetPassword()}
        buttonContent={t('resetPassword')}
        isButtonDisabled={isButtonDisabled}
        isSuccess={success}
        successText={t('tempPasswordSent')}
        isFailure={failure}
      />
    </>
  );
}