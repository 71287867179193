import { useEffect, useState } from 'react';
import { Snackbar, Checkbox, FormControlLabel, Grid, Link } from "@mui/material"
import { useNavigate } from 'react-router';
import { acceptCookies, isAnalyticCookiesAccepted, isCookiesAccepted } from '../../helpers/localStorageService';
import { useTranslation } from 'react-i18next';
import { CookieButton } from './cookieButton';
import { isDevEnvironment } from '../../env';

export function CookiesBar() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation('cookies');
    const [analyticCookieAccepted, setAnalyticCookieAccepted] = useState(true);

    const handleStatisticsCookieChange = () => {
        setAnalyticCookieAccepted(!analyticCookieAccepted);
    };

    useEffect(() => {
        if (!isCookiesAccepted())
            setOpen(true);
        else if (isAnalyticCookiesAccepted())
            updateGtagConsent(true);
    }, [])


    const acceptCookiesPolicy = () => {
        acceptCookies(analyticCookieAccepted);
        updateGtagConsent(analyticCookieAccepted);
        setOpen(false);
    };

    const acceptAllCookiesPolicy = () => {
        acceptCookies(true);
        updateGtagConsent(true);
        setOpen(false);
    };

    const handleCookiesPolicyClick = () => {
        navigate("/rules");
    };

    const updateGtagConsent = (analyticCookiesAccepted: boolean) => {
        if (isDevEnvironment())
            return;

        const consent = {
            'ad_storage': analyticCookiesAccepted ? "granted" : "denied",
            'analytics_storage': analyticCookiesAccepted ? "granted" : "denied",
            'ad_user_data': analyticCookiesAccepted ? "granted" : "denied",
            'ad_personalization': analyticCookiesAccepted ? "granted" : "denied",
        } as Gtag.ConsentParams;
        gtag('consent', 'update', consent);
    }

    const getCookiesAction = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                disabled
                                color="primary"
                            />
                        }
                        label={t("necessary")}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={analyticCookieAccepted}
                                onChange={handleStatisticsCookieChange}
                                color="primary"
                            />
                        }
                        label={t("analytics")}
                    />
                </Grid>
                <Grid item sx={{ mx: "5px" }}>
                    <Link sx={{
                        color: "black",
                        textDecorationColor: "black",
                        '&:hover': {
                            cursor: "pointer",
                        }
                    }} onClick={acceptCookiesPolicy}>
                        {t("acceptSelected")}
                    </Link>
                </Grid>
                <Grid item>
                    <CookieButton content={t("acceptAll")} onClick={acceptAllCookiesPolicy} />
                    <CookieButton content={t("policy")} onClick={handleCookiesPolicyClick} />
                </Grid>
            </Grid>
        )
    }

    return (
        <Snackbar
            sx={{
                '@media (min-width: 600px)': { minWidth: "560px" },
                "& .MuiSnackbarContent-action": {
                    margin: 0,
                    width: "100%"
                },
                whiteSpace: 'pre-line',
                textAlign: "center"
            }}
            open={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            message={t("message")}
            action={getCookiesAction()}
        />)
}