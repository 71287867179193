import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { drawerClasses } from '@mui/material';
import { To, useNavigate } from 'react-router';
import { CustomerInfo } from 'orderme-api-integration-client';
import { roundDoubleNumbers } from '../../helpers/currency';
import { LogoutConfirmation } from '../auth/logout/logoutConfirmation';
import { useTranslation } from 'react-i18next';
import { PasswordChange } from '../auth/accountSettings/passwordChange';
import { closeMenu } from '../../redux/reducers/menuReducer';
import BarcodeImage from "../../logo/app-barcode.png";
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledDrawer = styled(Drawer)({
    [`& .${drawerClasses.paper}`]: {
        boxSizing: "border-box",
        width: "100%",
        maxWidth: "400px",
        backgroundColor: "#71c1e4"
    }
});

export default function MenuDrawer() {
    const dispatch = useAppDispatch();
    const isMenuOpened: boolean = useAppSelector((state: RootState) => state.menuState.isMenuOpened);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const isUserLoggedIn: boolean = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const isPasswordRestored: boolean = useAppSelector((state: RootState) => state.authState.isPasswordRestored);
    const customerInfo: CustomerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
    const [openPasswordChange, setOpenPasswordChange] = useState(false);
    const { t } = useTranslation();
    const smallSize = useMediaQuery('(max-width:1024px)');

    useEffect(() => {
        setOpen(isMenuOpened);
    }, [isMenuOpened]);

    const handleDrawerClose = () => {
        dispatch(closeMenu());
    };

    const navigateAndClose = (to: To) => {
        handleDrawerClose();
        navigate(to);
    };

    const logout = () => {
        setOpenLogoutConfirmation(true);
        handleDrawerClose();
    }

    const closeLogoutConfirmation = () => {
        setOpenLogoutConfirmation(false);
    }

    const showPasswordChange = () => {
        handleDrawerClose();
        setOpenPasswordChange(true);
    }

    const closePasswordChange = () => {
        setOpenPasswordChange(false);
    }

    const downloadApp = () => {
        window.location.href = "https://onelink.to/5htyta";
    }

    return <Box>
        <StyledDrawer
            variant="temporary"
            open={open}
            onClose={handleDrawerClose}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                disableScrollLock: true
            }}
        >
            <Box sx={{ color: "white", textTransform: "uppercase" }}>
                <Box sx={{ textAlign: 'right' }}>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon sx={{ color: "white" }} />
                    </IconButton>
                </Box>
                <Divider />
                <List sx={{
                    ".MuiListItemButton-root": {
                        textAlign: "center",
                        py: "4px"
                    }
                }}>
                    {!isUserLoggedIn &&
                        (<ListItemButton>
                            <ListItemText onClick={() => { navigateAndClose("/login") }} primary={t('account:login')} />
                        </ListItemButton>)
                    }
                    {isUserLoggedIn &&
                        (<ListItem>
                            <Typography sx={{ fontWeight: "bold", width: "100%", textAlign: "center" }}>
                                {customerInfo.name} {roundDoubleNumbers(customerInfo.credit)} €
                            </Typography>
                        </ListItem>)
                    }
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/cart") }} primary={t('account:yourCart')} />
                    </ListItemButton>
                    {isUserLoggedIn && isPasswordRestored &&
                        (<ListItemButton>
                            <ListItemText primaryTypographyProps={{ fontWeight: "bold" }} onClick={() => { showPasswordChange() }} primary={t('account:changePassword')} />
                        </ListItemButton>)
                    }
                    {isUserLoggedIn && (
                        <ListItemButton disabled={isPasswordRestored}>
                            <ListItemText onClick={() => { navigateAndClose("/orderHistory") }} primary={t('account:ordersHistory')} />
                        </ListItemButton>)
                    }
                    {isUserLoggedIn &&
                        (<ListItemButton disabled={isPasswordRestored}>
                            <ListItemText onClick={() => { navigateAndClose("/addressBook") }} primary={t('account:addressBook')} />
                        </ListItemButton>)
                    }
                    {isUserLoggedIn &&
                        (<ListItemButton disabled={isPasswordRestored}>
                            <ListItemText onClick={() => { navigateAndClose("/accSettings") }} primary={t('account:settings')} />
                        </ListItemButton>)
                    }
                    {isUserLoggedIn && (
                        <ListItemButton disabled={isPasswordRestored}>
                            <ListItemText onClick={() => { navigateAndClose("/credits") }} primary={t('account:creditsAndCoupons')} />
                        </ListItemButton>)
                    }
                    <ListItemButton sx={{ mt: "30px" }}>
                        <ListItemText onClick={() => { navigateAndClose("/search") }} primary={t('info:allServices')} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/point/All") }} primary={t('salesPoint:salesPoints')} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/achievements") }} primary={t('achievement:achievements')} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/partners") }} primary={t('info:partners')} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/news") }} primary={t("news:menuTitle")} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/aboutUs") }} primary={t('info:aboutUs')} />
                    </ListItemButton>
                    <ListItemButton sx={{ mt: "30px" }}>
                        <ListItemText onClick={() => { navigateAndClose("/rules") }} primary={t('info:rules')} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/privacyPolicy") }} primary={t('info:privacyPolicy')} />
                    </ListItemButton>
                    {/* <ListItemButton>
                        <ListItemText onClick={() => { navigateAndClose("/home") }} primary={t('info:faq')} />
                    </ListItemButton> */}
                    {isUserLoggedIn &&
                        (<ListItemButton sx={{ mt: "30px" }}>
                            <ListItemText onClick={() => { logout() }} primary={t("account:logout")} />
                        </ListItemButton>)
                    }

                    <Box sx={{ textAlign: "center", mt: "30px" }}>
                        {smallSize ?
                            (<ListItemText sx={{ mt: "30px" }}
                                primaryTypographyProps={{
                                    border: "2px solid",
                                    display: "inline-block",
                                    borderRadius: "15px",
                                    py: "10px",
                                    px: "15px"
                                }} onClick={() => { downloadApp() }} primary={t("info:downloadApp")} />
                            ) :
                            (<>
                                <ListItemText sx={{ mt: "30px" }} primary={t("info:downloadApp")} />
                                <img alt="paulini-barcode" src={BarcodeImage} width={120} height={120}></img>
                            </>)
                        }
                    </Box>
                </List>
            </Box >
        </StyledDrawer>
        <LogoutConfirmation open={openLogoutConfirmation} onClose={closeLogoutConfirmation} />
        <PasswordChange open={openPasswordChange} onClose={closePasswordChange} />
    </Box>
}