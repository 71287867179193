import { Divider, Typography } from "@mui/material";
import { styled } from '@mui/system';

export const CopyrightTypography = styled(Typography)({
    fontSize: "15px",
});

export const CopyrightDivider = styled(Divider)({
    border: "1px solid rgba(255, 255, 255, 0.2)",
    width: "100%",
    marginBottom: "10px",
});
