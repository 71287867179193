import { useEffect, useState } from 'react';
import { instance } from '../helpers/client';
import { assignCustomerDetails } from '../helpers/customerHelper';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { RootState } from '../redux/store';
import { clearCustomer } from '../redux/reducers/customerReducer';
import tokenRefresher from '../helpers/tokenRefresher';
import { login } from '../redux/reducers/authReducer';
import { addServicesFromStorage, verifyServices } from '../helpers/cartVeryfier';
import { getCurrentLanguage } from '../i18n/language';

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const dispatch = useAppDispatch();
    const [isLoaded, setIsLoaded] = useState(false);
    const isUserLoggedIn = useAppSelector((state: RootState) => state.authState.isLoggedIn);
    const accessToken = useAppSelector((state: RootState) => state.authState.accessToken);
    const customerInfo = useAppSelector((state: RootState) => state.customerState.customerInfo);
    const servicesInBasket = useAppSelector((state: RootState) => state.cartState.servicesInBasket);

    tokenRefresher.setupInterceptors();
    instance.defaults.headers.common["Accept-Language"] = getCurrentLanguage();

    useEffect(() => {
        if (accessToken) {
            instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            assignCustomerDetails().then(() => {
                setIsLoaded(true);
            }).catch(err => {
                console.log(err);
                setIsLoaded(true);
            });
        }
        else {
            instance.defaults.headers.common['Authorization'] = null;
            setIsLoaded(true);
        }

    }, [accessToken]);

    useEffect(() => {
        if (isLoaded && servicesInBasket.length === 0)
            addServicesFromStorage();
    }, [isLoaded]);

    useEffect(() => {
        if (!isUserLoggedIn) {
            if (customerInfo.id) {
                dispatch(clearCustomer());
            }
        }
    }, [isUserLoggedIn]);

    useEffect(() => {
        if (customerInfo.id && !isUserLoggedIn) {
            dispatch(login());
        }
        verifyServices();
    }, [customerInfo]);

    return isLoaded && children
};

export default AuthProvider;
