import { OrderStatus } from "orderme-api-integration-client";
import i18next from '../i18n/i18n';

const localizedStatus = {
    WaitingForPayment: i18next.t('waitingForPayment', { ns: 'orderStatus' }),
    Processing: i18next.t('processing', { ns: 'orderStatus' }),
    AcceptedBySalesPoint: i18next.t('acceptedBySalesPoint', { ns: 'orderStatus' }),
    ReadyToPickUpByCourier: i18next.t('accepted', { ns: 'orderStatus' }),
    Accepted: i18next.t('accepted', { ns: 'orderStatus' }),
    PickedUpByCourier: i18next.t('pickedUpByCourier', { ns: 'orderStatus' }),
    Ready: i18next.t('ready', { ns: 'orderStatus' }),
    Completed: i18next.t('completed', { ns: 'orderStatus' }),
    Cancelled: i18next.t('cancelled', { ns: 'orderStatus' }),
}

export const getStatusTranslation = (orderStatus: OrderStatus) => {
    return localizedStatus[OrderStatus[orderStatus]];
}

export const getStatusColor = (enumStatus: OrderStatus) => {
    switch (enumStatus) {
        case OrderStatus.WaitingForPayment:
        case OrderStatus.Cancelled:
            return "#a4a4a4";
        case OrderStatus.Processing:
            return "#de0403";
        case OrderStatus.AcceptedBySalesPoint:
            return "#ed7001";
        case OrderStatus.Accepted:
        case OrderStatus.ReadyToPickUpByCourier:
            return "#ffad01";
        case OrderStatus.PickedUpByCourier:
            return "#ffe500";
        case OrderStatus.Ready:
            return "#18df00";
        case OrderStatus.Completed:
            return "#0195d6";
    }
}

export const getProgress = (enumStatus: OrderStatus) => {
    switch (enumStatus) {
        case OrderStatus.WaitingForPayment:
        case OrderStatus.Cancelled:
            return 0;
        case OrderStatus.Processing:
            return 20;
        case OrderStatus.AcceptedBySalesPoint:
            return 35;
        case OrderStatus.Accepted:
        case OrderStatus.ReadyToPickUpByCourier:
            return 45;
        case OrderStatus.PickedUpByCourier:
            return 60;
        case OrderStatus.Ready:
            return 80;
        case OrderStatus.Completed:
            return 100;
    }
}