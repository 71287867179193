import { Typography, Grid, Box } from "@mui/material";
import { CardBox, KeyArrowRightIconStyled, ListItemStyled } from "./accountStyles"

interface AccountItemProps {
  title: string;
  open: () => void;
  icon: any;
  isVisible?: boolean;
  isDisabled?: boolean;
  isBold?: boolean;
}

export function AccountItem(props: AccountItemProps) {
  const { title, open, icon, isVisible = true, isDisabled, isBold } = props;

  return (
    <CardBox boxShadow={3} sx={{ display: !isVisible && "none" }} >
      <ListItemStyled disabled={isDisabled} onClick={() => open()}>
        <Grid container direction="row" sx={{ margin: "5px" }}>
          <Box sx={{ color: "paulini.orange", marginRight: "20px" }}>{icon}</Box>
          <Typography sx={{ fontWeight: isBold ? "bold" : "normal" }}>{title}</Typography>
          <KeyArrowRightIconStyled />
        </Grid>
      </ListItemStyled>
    </CardBox>
  );
}