import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { HtmlViewer } from "../../controls/html/htmlViewer";
import { InfoFeedClient } from "../../helpers/client";
import { BreadcrumbsBar } from "../breadcrumbs/breadcrumbsBar";

export function TermsAndConditions() {
    const infoFeedsClient = new InfoFeedClient();
    const [termsAndConditions, setTermsAndConditions] = useState<string>();

    useEffect(() => {
        getPrivacyPolicy();
    }, [])

    const getPrivacyPolicy = () => {
        infoFeedsClient.getCompanyTermsAndConditions().then(response => {
            setTermsAndConditions(response);
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        window.scrollTo({ behavior: "smooth", top: 0 });
    }, []);

    return (
        <Grid container direction="column" alignItems="center">
            <BreadcrumbsBar to={"rules"} />
            <Grid sx={{
                maxWidth: "1000px",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "15px"
            }}>
                <HtmlViewer content={termsAndConditions} />
            </Grid>
        </Grid>
    );
}