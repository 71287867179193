import { store } from "../redux/store";

const packingFee: number = 0;

export const originalCartWorth = () => {
    var servicesInBasket = store.getState().cartState.servicesInBasket;
    if (servicesInBasket.length === 0) {
        return 0;
    }

    var sum = servicesInBasket.map(a => a.originalPrice).reduce((a, b) => { return a + b; });
    return Math.round(sum * 100) / 100
}

export const cartWorth = () => {
    var servicesInBasket = store.getState().cartState.servicesInBasket;
    if (servicesInBasket.length === 0) {
        return 0;
    }

    var sum = servicesInBasket.map(a => a.price).reduce((a, b) => { return a + b; });
    return Math.round(sum * 100) / 100
}

export const getCredits = () => {
    var credit: number = store.getState().customerState.customerInfo.credit;
    if (!credit) {
        return 0;
    }
    return credit;
}

export const getFullPrice = () => {
    return cartWorth() + packingFee;
}

export const getCreditsUsed = () => {
    return getFullPrice() > getCredits() ? getCredits() : getFullPrice();
}

export const getFinalPrice = () => {
    return getFullPrice() - getCreditsUsed();
}

export const savings = () => {
    return originalCartWorth() - cartWorth()
}