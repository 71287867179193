import PauliniLogo from "../../logo/logo.png"
import { ImageLoader } from "../imgLoader/imgLoader";
import { GridLayout } from "./initialPageStyles";

export function StartPage() {

  return (
    <GridLayout container direction="column" justifyContent="center" alignItems="center">
      <ImageLoader sx={{ width: "200px", marginBottom: "90px" }} src={PauliniLogo} alt="Logotipas" />
    </GridLayout>
  );
}