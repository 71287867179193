import { useEffect, useState } from "react";
import { List, Grid, InputAdornment, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ErrorMessageBox, LinkStyled, LockIconImg, PhoneIconImg } from "./loginStyles"
import { DialogProps, LoginFieldValues } from "../../../interfaces/interfaces"
import PhoneIcon from "../../../logo/smartphone.png"
import LockIcon from "../../../logo/lock.png"
import { ResetPassword } from "../resetPassword/resetPassword"
import { InputField } from "../../inputField/inputField"
import { Popup } from "../../popup/popup"
import { useTranslation } from 'react-i18next';
import { FacebookSignIn, GoogleSignIn } from "../../../controls";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { authenticate, setRememberMe } from "../../../redux/reducers/authReducer";
import { CheckBox } from "../../checkBox/checkBox";
import { RootState } from "../../../redux/store";
import { AppleSignIn } from "../externalLogin/appleSignIn";
import { appStyleMode } from "../../../helpers/clientConfigs";
import { AppStyle } from "../../../helpers/appStyle";

const inputValues = {
  loginName: '',
  password: '',
}

export function LoginDialog(props: DialogProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { onClose, open } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [textFieldValues, setTextFieldValues] = useState<LoginFieldValues>(inputValues);
  const [showPsw, setShowPsw] = useState(false);
  const [showPswReset, setShowPswReset] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const rememberMe = useAppSelector((state: RootState) => state.authState.rememberMe)

  const { t } = useTranslation('account');

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
    onClose();
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;
    const fieldValue = { [name]: value } as Pick<LoginFieldValues, keyof LoginFieldValues>;

    setTextFieldValues({
      ...textFieldValues,
      ...fieldValue
    });
  }

  const login = async () => {
    setIsButtonDisabled(true);
    setSuccess(false);
    setFailure(false);

    dispatch(authenticate({ input: textFieldValues.loginName, password: textFieldValues.password })).unwrap()
      .then(() => {
        setErrorMessage(null);
        setSuccess(true);
        setTextFieldValues(inputValues);
        setIsButtonDisabled(false);
        handleClose();
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        onLoginFailure(error);
        return;
      });
  }

  useEffect(() => {
    validate();
  }, [textFieldValues]);

  const validate = () => {
    if (Object.values(textFieldValues).every(value => value !== "")) {
      setIsButtonDisabled(false);
    }
    else {
      setIsButtonDisabled(true);
    }
  }

  const onLoginFailure = (err: string) => {
    setErrorMessage(err);
    setFailure(true);
  }

  const openResetPassword = () => {
    handleClose();
    setShowPswReset(true);
  }

  const closeResetPassword = () => {
    setShowPswReset(false);
  }

  return (
    <>
      <Popup onClose={() => handleClose()} open={open} dialogTitle={t('loginToAccount')} dialogContent={
        <>
          <List>
            <InputField fieldName="loginName" label={t('loginInput')} handleFieldChange={handleFieldChange} icon={<PhoneIconImg src={PhoneIcon} />} />
            <InputField isPassword={!showPsw} fieldName="password" label={t('password')} handleFieldChange={handleFieldChange} icon={<LockIconImg src={LockIcon} />} endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPsw(!showPsw)} edge="end">
                  {showPsw ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>} />
          </List>
          <Grid container justifyContent="center" alignItems="center" direction="column">
            {appStyleMode !== AppStyle.Mobile &&
              <Grid sx={{ width: "220px", mb: "10px" }} container>
                <CheckBox isChecked={rememberMe} onChange={() => dispatch(setRememberMe(!rememberMe))} />
                <Typography sx={{ ml: "20px" }} onClick={() => dispatch(setRememberMe(!rememberMe))}>{t('rememberMe')}
                </Typography>
              </Grid>
            }
            <GoogleSignIn isVisible={true} onSignedIn={() => handleClose()} onError={err => onLoginFailure(err)} />
            <FacebookSignIn isVisible={true} onSignedIn={() => handleClose()} onError={err => onLoginFailure(err)} />
            <AppleSignIn isVisible={true} onError={err => onLoginFailure(err)} />
            {errorMessage && <ErrorMessageBox>{errorMessage}</ErrorMessageBox>}
            <LinkStyled underline="hover" onClick={() => openResetPassword()}>{t('forgotPassword')}</LinkStyled>
            <LinkStyled underline="hover" onClick={() => navigate("/register")}>{t('registration')}</LinkStyled>
          </Grid>
        </>
      }
        onAction={() => login()}
        buttonContent={t('login')}
        isButtonDisabled={isButtonDisabled}
        isSuccess={success}
        isFailure={failure} />
      <ResetPassword open={showPswReset} onClose={closeResetPassword} />
    </>
  );
}